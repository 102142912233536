import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ma-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            { attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(VIcon, { staticClass: "primary--text pa-2" }, [
                    _vm._v(" fa-sharp fa-solid fa-percent ")
                  ]),
                  _vm._v("Renegociação > Gerenciar Ofertas para um Título ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "my-3 pa-3",
              staticStyle: { "border-radius": "20px" },
              attrs: { color: "primary" }
            },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3",
                      attrs: { cols: "3", xs: "3", md: "3" }
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "white--text ma-6 pa-6 mx-auto my-auto",
                          attrs: { size: "100", "elevation-8": "" }
                        },
                        [_vm._v(" fa-sharp fa-solid fa-percent ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3 mx-auto my-auto",
                      attrs: {
                        cols: "12",
                        sm: "12",
                        md: "5",
                        align: "left",
                        justify: "left"
                      }
                    },
                    [
                      _c(
                        VCardText,
                        { staticClass: "white--text display-1" },
                        [
                          _vm._v(" Gerenciar Ofertas "),
                          _c("strong", [_vm._v("Título")]),
                          _c(
                            VChip,
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "secondary",
                                label: "",
                                "text-color": "white"
                              }
                            },
                            [
                              _c(VIcon, { attrs: { left: "" } }, [
                                _vm._v(" mdi-label ")
                              ]),
                              _vm._v(" Novidade! ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3 mx-auto my-auto",
                      attrs: {
                        sm: "12",
                        md: "2",
                        align: "left",
                        justify: "left"
                      }
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "white--text font-weight-light subtitle mb-3 pa-3"
                        },
                        [
                          _vm._v(
                            " Crie e gerencie ofertas para títulos, podendo criar com base em valores, porcentagem de desconto e valores. "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                {
                  staticClass: "ma-6",
                  attrs: { "elevation-0": "", outlined: "" }
                },
                [
                  _c(
                    VExpansionPanels,
                    [
                      _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            { staticClass: "grey lighten-4" },
                            [
                              _c(
                                VCardText,
                                {
                                  staticClass: "font-weight-light black--text"
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticClass: "primary--text lighten-2" },
                                    [_vm._v(" search ")]
                                  ),
                                  _vm._v(" Filtrar Resultados ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                { staticClass: "my-4" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "9",
                                        sm: "12",
                                        md: "12",
                                        lg: "2"
                                      }
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          label: "Id da Oferta",
                                          hint: "Informe o id único da oferta",
                                          placeholder: "0001"
                                        },
                                        model: {
                                          value: _vm.filtros.id_oferta,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "id_oferta",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.id_oferta"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "my-auto",
                                                      attrs: {
                                                        color:
                                                          "grey lighten-2\n"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          'Cada oferta possui um número único chamado de "id". Informe o id da oferta que deseja encontrar.'
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "9",
                                        xs: "12",
                                        sm: "12",
                                        lg: "2"
                                      }
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          label: "Número do Título",
                                          hint: "Informe o número do título",
                                          placeholder: "33344401"
                                        },
                                        model: {
                                          value: _vm.filtros.numero_titulo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "numero_titulo",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.numero_titulo"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "grey lighten-2"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Informe o número do Título na Recuperi"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "mx-2 d-flex justify-center align-center",
                                      attrs: {
                                        cols: "6",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(VCheckbox, {
                                        staticClass:
                                          "mt-n1 d-flex justify-center",
                                        attrs: {
                                          label: "Somente Ofetas ativas",
                                          "item-text": "nm_status",
                                          "item-value": "status",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.status,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filtros, "status", $$v)
                                          },
                                          expression: "filtros.status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(VCol, {
                                    staticClass: "mx-2",
                                    attrs: {
                                      cols: "6",
                                      xs: "2",
                                      sm: "2",
                                      md: "3",
                                      lg: "3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "pa-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.aplicarFiltros.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                { staticClass: "my-1" },
                [
                  _c(VCol, {
                    staticClass: "mx-3",
                    attrs: { cols: "4", xs: "2", sm: "2", md: "7", lg: "7" }
                  }),
                  _c(VCol, {
                    staticClass: "mx-2",
                    attrs: {
                      cols: "11",
                      xs: "6",
                      sm: "6",
                      md: "2",
                      lg: "2",
                      align: "center"
                    }
                  }),
                  _c(
                    VCol,
                    {
                      staticClass: "mx-2",
                      attrs: {
                        cols: "11",
                        xs: "6",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mt-n1 d-flex justify-center",
                          attrs: {
                            width: "180",
                            color: "primary",
                            loading: _vm.loadingFiltros
                          },
                          on: { click: _vm.botaoFiltrar }
                        },
                        [
                          _vm._v(" Buscar "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemAviso
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3 mt-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.ofertas,
              "item-key": "ofertas"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.id",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("span", [_vm._v(" " + _vm._s(item.id) + " ")])]
                  }
                },
                {
                  key: "item.nosso_numero",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "d-flex justify-center align-center"
                            },
                            [_vm._v(" " + _vm._s(item.nosso_numero) + " ")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-3",
                              attrs: { "x-small": "", elevation: "0", fab: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(item)
                                }
                              }
                            },
                            [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.valor_de",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.valor_de)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.valor_ate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.valor_ate)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.data_inicio",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.data_inicio)) + " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.data_fim",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.formatDate(item.data_fim)) + " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.desconto_porcentagem",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatPorcentagem(item.desconto_porcentagem)
                            ) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.desconto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDinheiroString(item.desconto)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.status != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.status != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.status,
                          callback: function($$v) {
                            _vm.$set(item, "status", $$v)
                          },
                          expression: "item.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: { color: item.cartao != "" ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.cartao)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.juros_cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_cartao)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.boleto == true ? "green" : "red"
                        },
                        attrs: {
                          color: item.boleto == true ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.boleto,
                          callback: function($$v) {
                            _vm.$set(item, "boleto", $$v)
                          },
                          expression: "item.boleto"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.juros_boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_boleto)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 grey white--text",
                          attrs: {
                            small: "",
                            elevation: "1",
                            width: "100%",
                            loading: _vm.loadingFiltros
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(item)
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(item)
                                }
                              }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v(" Ver Título ")
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.action_desabilitar",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.status == true
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mr-2 grey white--text",
                              attrs: {
                                small: "",
                                elevation: "1",
                                width: "100%",
                                loading: _vm.loadingFiltros
                              },
                              on: {
                                click: function($event) {
                                  return _vm.botaodesabilitarOferta(item)
                                }
                              }
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "mr-2",
                                  style: { color: "white" },
                                  attrs: { small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.botaodesabilitarOferta(item)
                                    }
                                  }
                                },
                                [_vm._v("close")]
                              ),
                              _vm._v(" Desabilitar ")
                            ],
                            1
                          )
                        : _vm._e(),
                      item.status == false
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mr-2 grey white--text",
                              attrs: {
                                small: "",
                                elevation: "1",
                                width: "100%",
                                disabled: "",
                                loading: _vm.loadingFiltros
                              }
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "mr-2",
                                  style: { color: "white" },
                                  attrs: { small: "" }
                                },
                                [_vm._v("mdi-information")]
                              ),
                              _vm._v(" Desabilitado ")
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialogTitulo
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogTitulo,
                callback: function($$v) {
                  _vm.dialogTitulo = $$v
                },
                expression: "dialogTitulo"
              }
            },
            [
              _c("validation-observer", {
                ref: "observer",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var invalid = ref.invalid
                        return [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "form",
                                { ref: "ofertaForm" },
                                [
                                  _c(
                                    VCard,
                                    { staticClass: "ml-1" },
                                    [
                                      _c(
                                        VToolbar,
                                        {
                                          attrs: {
                                            flat: "",
                                            dark: "",
                                            color: "primary"
                                          }
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { icon: "", dark: "" },
                                              on: { click: _vm.closeDialog }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-close")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            VCol,
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "2",
                                                align: "left"
                                              }
                                            },
                                            [
                                              _c(VToolbarTitle, [
                                                _vm._v("Detalhes Titulo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardText,
                                        [
                                          _c("DetalheTitulo", {
                                            attrs: {
                                              titulo: _vm.titulo,
                                              setAtualizarTitulo:
                                                _vm.setAtualizarTitulo,
                                              mostrarConfirmacao:
                                                _vm.mostrarConfirmacao,
                                              habilitaCorrecao:
                                                _vm.habilitaCorrecao
                                            },
                                            on: {
                                              fecharModal: _vm.closeDialog,
                                              showSnackbar:
                                                _vm.snackbarDetalheTitulo,
                                              atualizarTitulo:
                                                _vm.atualizarTitulo
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VContainer,
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass: "pa-2",
                                              attrs: {
                                                align: "baseline",
                                                justify: "end"
                                              }
                                            },
                                            [
                                              _vm.oferta.id
                                                ? _c(
                                                    VBtn,
                                                    {
                                                      staticClass: "ml-5",
                                                      attrs: {
                                                        dense: "",
                                                        color:
                                                          "grey white--text"
                                                      },
                                                      on: {
                                                        click: _vm.closeDialog
                                                      }
                                                    },
                                                    [_vm._v(" Fechar ")]
                                                  )
                                                : _vm._e(),
                                              !_vm.oferta.id
                                                ? _c(
                                                    VBtn,
                                                    {
                                                      staticClass: "ml-5",
                                                      attrs: {
                                                        dense: "",
                                                        color: "error"
                                                      },
                                                      on: {
                                                        click: _vm.closeDialog
                                                      }
                                                    },
                                                    [_vm._v(" Cancelar ")]
                                                  )
                                                : _vm._e(),
                                              !_vm.oferta.id
                                                ? _c(
                                                    VBtn,
                                                    {
                                                      staticClass: "ml-5",
                                                      attrs: {
                                                        color:
                                                          "green white--text",
                                                        disabled: invalid
                                                      },
                                                      on: {
                                                        click: _vm.salvarOferta
                                                      }
                                                    },
                                                    [_vm._v(" Salvar ")]
                                                  )
                                                : _vm._e(),
                                              _c(VIcon, {
                                                staticClass: "ml-2"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3441132919
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "450px"
          },
          model: {
            value: _vm.dialogLoading,
            callback: function($$v) {
              _vm.dialogLoading = $$v
            },
            expression: "dialogLoading"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center", attrs: { width: "420px" } },
            [
              _c(
                VCard,
                [
                  _c(
                    VProgressCircular,
                    {
                      staticClass: "ma-12",
                      attrs: {
                        indeterminate: "",
                        size: "150",
                        color: "primary"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.mensagemLoading) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }